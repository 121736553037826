<!--
File: Report_10_Section_wise_AADT.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('label.select_region')" v-model="selectedRegion" :items="regionsInReport10"
            :displayField="'name'" :valueField="'id'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('label.select_road')" v-model="selectedRoad" :items="roadsList"
            :displayField="'name'" :valueField="'id'" :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('road_network.survey_year')" v-model="selectedYear" :items="yearsInReport10"
            :displayField="'year'" :valueField="'year'" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled || report.length === 0">
            {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />

      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="max-width: 200px;" :md-label="$t('road_network.section')"
            md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell style="max-width: 200px;" :md-label="$t('road_network.survey_year')" md-sort-by="survey_year"
            md-numeric>
            {{ item.survey_year }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_a1_spc')" md-sort-by="aadt_a1_spc" md-numeric>
            {{ item.aadt_a1_spc | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b1_mb')" md-sort-by="aadt_b1_mb" md-numeric>
            {{ item.aadt_b1_mb | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b2_l2tr')" md-sort-by="aadt_b2_l2tr" md-numeric>
            {{ item.aadt_b2_l2tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b3_tractors')" md-sort-by="aadt_b3_tractors" md-numeric>
            {{ item.aadt_b3_tractors | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_c1_m2tr')" md-sort-by="aadt_c1_m2tr" md-numeric>
            {{ item.aadt_c1_m2tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_c2_h3tr')" md-sort-by="aadt_c2_h3tr" md-numeric>
            {{ item.aadt_c2_h3tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d1_buses')" md-sort-by="aadt_d1_buses" md-numeric>
            {{ item.aadt_d1_buses | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d2_truck_tr')" md-sort-by="aadt_d2_truck_tr" md-numeric>
            {{ item.aadt_d2_truck_tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d3_ar4tr_tr')" md-sort-by="aadt_d3_ar4tr_tr" md-numeric>
            {{ item.aadt_d3_ar4tr_tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_total')" md-sort-by="aadt_total" md-numeric>
            {{ item.aadt_total | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import { mapGetters } from 'vuex'
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'

export default {
  mixins: [customSortMixin, printReport],
  name: 'report-section_wise_aadt',
  data() {
    return {
      selectedRegion: null,
      selectedRoad: null,
      selectedYear: null,
      btnDisabled: true,

      showSpinner: false,
      currentSort: 'section_description',
      currentSortOrder: 'asc',
    }
  },
  components: {
    BaseDropdown,
  },

  mounted() {
    this.reloadData()
    if (this.yearsInReport10.length > 0) this.selectedYear = this.yearsInReport10[this.yearsInReport10.length - 1].year
  },

  methods: {
    async reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      await this.$store.dispatch('REPORT_10_SECTION_WISE_AADT')
      this.btnDisabled = false
      this.showSpinner = false
    },

    print() {
      this.showSpinner = true
      let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
      prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report10_title')
      prtHtml += '&nbsp;' + this.$t('label.as_on') + this.selectedYear + '<br>'

      let titleFilters = ''
      let tableHeaders = '<tr>'
      if (this.selectedRegion) {
        titleFilters += this.$t('label.from_region', { name: this.report[0].region_description })
      }
      if (this.selectedRoad) {
        titleFilters += ', &nbsp;' + this.$t('road_network.road') + ': ' + this.report[0].road_description
      }
      if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
      prtHtml += '</h4>'

      tableHeaders += `<th>${this.$t('road_network.section')}</th>
          <th>${this.$t('traffic.aadt_a1_spc')}</th>
          <th>${this.$t('traffic.aadt_b1_mb')}</th>
          <th>${this.$t('traffic.aadt_b2_l2tr')}</th>
          <th>${this.$t('traffic.aadt_b3_tractors')}</th>
          <th>${this.$t('traffic.aadt_c1_m2tr')}</th>
          <th>${this.$t('traffic.aadt_c2_h3tr')}</th>
          <th>${this.$t('traffic.aadt_d1_buses')}</th>
          <th>${this.$t('traffic.aadt_d2_truck_tr')}</th>
          <th>${this.$t('traffic.aadt_d3_ar4tr_tr')}</th>
          <th>${this.$t('traffic.aadt_total')}</th>
        </tr>`

      let tableRows = ''
      this.report.forEach(item => {
        tableRows += `<tr><td>${item.section_description}</td>
            <td class='numCell'>${numFormat(item.aadt_a1_spc)}</td>
            <td class='numCell'>${numFormat(item.aadt_b1_mb)}</td>
            <td class='numCell'>${numFormat(item.aadt_b2_l2tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_b3_tractors)}</td>
            <td class='numCell'>${numFormat(item.aadt_c1_m2tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_c2_h3tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_d1_buses)}</td>
            <td class='numCell'>${numFormat(item.aadt_d2_truck_tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_d3_ar4tr_tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_total)}</td>
          </tr>`
      })

      this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
    },
  },

  computed: {
    ...mapGetters(['report10_filtered', 'regionsInReport10', 'roadsInReport10', 'yearsInReport10']),
    report() {
      return this.report10_filtered(this.selectedRegion, this.selectedRoad, this.selectedYear)
    },
    roadsList() {
      return this.roadsInReport10(this.selectedRegion)
    }
  },

  watch: {
    selectedRegion() {
      this.selectedRoad = null
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 13px !important;
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  font-size: 12px !important;
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>